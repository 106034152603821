import React from 'react'
import { Link } from 'gatsby'
import CustomLink from 'components/CustomLink'

// CSS
import './Elements.scss'

export const ButtonDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonAlt = ({ isAnchor, to, children, className }) => {
  const classes = `button button-alt${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const TextLink = ({ isAnchor, to, children, className }) => {
  const classes = `textlink${className ? ` ${className}` : ``}`

  return (
    <Link to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </Link>
  )
}

export const ButtonLineDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonLineAlt = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-alt${className ? ` ${className}` : ``}`

  return <ButtonShell to={to} isAnchor={isAnchor} className={classes}>{children}</ButtonShell>
}

const ButtonShell = ({ isAnchor, to, children, className }) => (
  !isAnchor ? (
    <CustomLink to={to} className={className}>{children}</CustomLink>
  ) : (
    <a href={to} className={className}>{children}</a>
  )
)

export const TitleDefault = ({ children, className }) => (
  <h1 className={`title title-default${className ? ` ${className}` : ``}`}>{children}</h1>
)

export const TitleAlt = ({ children, className }) => (
  <h2 className={`title title-alt${className ? ` ${className}` : ``}`}>{children}</h2>
)

export const TitleSubDefault = ({ children, className }) => (
  <div className={`title title-sub-default${className ? ` ${className}` : ``}`}>{children}</div>
)

export const BoxDefault = ({ children, className }) => (
  <div className={`box${className ? ` ${className}` : ``}`}>{children}</div>
)

export const ContentDefault = ({ children, className }) => (
  <div className={`content${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const BannerDefault = ({ children, className }) => (
  <div className={`banner${className ? ` ${className}` : ``}`}>
    <div className="container">
      {children}
    </div>
  </div>
)