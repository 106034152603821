import React from 'react'
import CookieConsent from 'react-cookie-consent'

// Components
import Header from './Header'
import Footer from './Footer'
import ChatButton from './ChatButton'

// CSS
import 'styles/all.scss'

const Layout = ({ children, subFooter }) => (
  <>
    <Header />
    <>{children}</>
    <ChatButton />
    <Footer subFooter={subFooter} />
    <CookieConsent
      acceptOnScroll
      acceptOnScrollPercentage={20}
      location="bottom"
      style={{ backgroundColor: '#DE7B11', color: '#fff' }}
      buttonClasses="d-none"
    >
      <div className="container text-center font-weight-l">
        This website uses cookies to enhance the user experience.
      </div>
    </CookieConsent>

  </>
)

export default Layout
