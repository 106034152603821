import { navigate } from 'gatsby'

export const isBrowser = () => typeof window !== 'undefined'

import { each } from 'lodash'

export const languages = {
  // '/en/': 'en_US',
  '/fr/': 'fr_FR',
  '/es/': 'es_ES',
  '/de/': 'de_DE',
}

export const getLanguage = () => {
  let response = 'en_US'

  each(languages, (value, index) => {
    if(isBrowser() && window.location.href.includes(index)){
      response = value

      return false
    }
  })

  return response
}

  

export const setLanguage = node => navigate(node.path)
