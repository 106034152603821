/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

// Images
import chatIcon from 'img/chaticon.svg'

// Language
import { getLanguage } from 'services/language'

// CSS
import './ChatButton.scss'

const ProfilePicture = styled(BackgroundImage)`
  border-radius: 50%;
  overflow: hidden;

  @media (min-width: 992px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 991px) {
    height: 40px;
    width: 40px;
  }
`

const ChatButton = () => {
  const language = getLanguage()

  const { buttons } = useStaticQuery(graphql`
    {
      buttons: allWordpressWpComponents(
        filter: { slug: { eq: "chat-button" } }
      ) {
        edges {
          node {
            title
            acf {
            phoneNumber: phonenumber
            title
            profileImage: profile_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            messages {
              message
            }
          }
            locale: wpml_current_locale
          }
        }
      }
    }
  `)

  const [showMessages, setShowMessages] = useState(false)

  useEffect(() => {
    let timerTime = 5000

    if(typeof localStorage !== 'undefined') {
      const closedTime = localStorage.getItem('chat-closed')

      if(closedTime) {
        const seconds = Math.round((new Date().getTime() - closedTime) / 1000)

        timerTime = 120 - seconds

        if(timerTime < 5) {
          timerTime = 5
        }

        timerTime *= 1000
      }
    }

    setTimeout(() => {
      setShowMessages(true)
    }, timerTime)
  }, [])

  const buttonContent = buttons.edges.find(({ node: { locale } }) => locale === language)

  return (
    <div className="chat-function">
      {showMessages && (
        <div className="chat-function-messages mb-3">
          <div className="chat-function-messages-header py-1 py-lg-2 px-4 d-flex align-items-center justify-content-between">
            <div>
              <img src={chatIcon} width="100" alt="Questions?" />
            </div>
            <div className="text-uppercase pl-4 mr-auto">
              {buttonContent.node.acf.title}
            </div>
            <div
              role="button"
              onClick={() => {
                if(typeof localStorage !== 'undefined') {
                  localStorage.setItem('chat-closed', new Date().getTime());
                }

                setShowMessages(false)
              }}
            >
              <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" /></svg>
            </div>
          </div>
          <div className="chat-function-messages-content py-3 px-4 d-flex">
            <div>
              <ProfilePicture fluid={buttonContent.node.acf.profileImage.localFile.childImageSharp.fluid} />
            </div>
            <div className="px-3">
              {buttonContent.node.acf.messages.map(({ message }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="chat-function-messages-content-message py-2 py-lg-3 px-3 mb-3">
                  {message}
                </div>
              ))}
            </div>
          </div>
          <div className="py-3 py-lg-5">
            <a href={`https://api.whatsapp.com/send?phone=${buttonContent.node.acf.phoneNumber}`} className="chat-function-messages-content-input mt-lg-5 d-flex align-items-center" target="_blank" rel="noopener noreferrer">
              <div className="chat-function-messages-content-input-cursor" />
            </a>
          </div>
        </div>
      )}
      <div className="text-right">
        <a href={`https://api.whatsapp.com/send?phone=${buttonContent.node.acf.phoneNumber}`} className="chat-function-link" target="_blank" rel="noopener noreferrer">
          <img src={chatIcon} width="100" alt="Questions?" />
        </a>
      </div>
    </div>
  )
}

export default ChatButton
