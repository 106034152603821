import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

import {
  find as _find,
  map as _map
} from 'lodash'

// Images
import imgLogo from 'img/logo.svg'
import arrow from 'img/button-arrow.svg'
import phone from 'img/phone.svg'

// Language
import { getLanguage } from 'services/language'

// Components
import CustomLink from 'components/CustomLink'
import LanguageSwitch from 'components/LanguageSwitch'

// CSS
import './Header.scss'

const Header = () => {
  const [isCollapsed, setCollapsed] = useState(true)
  const language = getLanguage()

  const {
    headers,
    pages,
  } = useStaticQuery(graphql`
    {
      headers: allWordpressWpComponents(filter: {slug: {eq: "header"}}) {
        edges {
          node {
            title
            acf {
              address: adress_left
              loginText: login_text
              loginURL: login_url
              phonenumber: phone_number
              links: header_links
            }
            locale: wpml_current_locale
          }
        }
      }

      pages: allWordpressPage {
        edges {
          node {
            wordpressId: wordpress_id
            path
            title
          }
        }
      }
    }
  `)

  const headerContent = _find(headers.edges, ({ node: { locale } }) => locale === language)

  return (
    <nav className="header position-relative">
      <div className="topbar position-relative">
        <div className="topbar-container container">
          <p className="adres">{headerContent.node.acf.address}</p>
          <a href={headerContent.node.acf.loginURL} className="login" target="_blank" rel="noopener noreferrer">
            <img src={arrow} width="25" alt="" />
            <span>{headerContent.node.acf.loginText}</span>
          </a>
        </div>
      </div>
      <div className="navbar-container brand container position-relative">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={imgLogo} className="logo" alt="" />
          </Link>
        </div>
        <div className="navbar-cta">
          <img src={phone} width="50" alt="Call us" />
          <a href={`tel:${headerContent.node.acf.phonenumber}`}>
            <span>Call us</span>
            <br />
            {headerContent.node.acf.phonenumber}
          </a>
        </div>
      </div>
      <div className="navbar-container menu container">
        <div className="navbar-menu">
          <button
            type="button"
            onClick={() => setCollapsed(!isCollapsed)}
            className={`navbar-collapse-button${
              isCollapsed ? ' navbar-collapse-button-collapsed' : ''
            } text-center d-block d-lg-none`}
          >
            {isCollapsed ? (
              <>
                <span className="navbar-collapse-button-stripe d-block" />
                <span className="navbar-collapse-button-stripe d-block" />
                <span className="navbar-collapse-button-stripe d-block" />
              </>
            ) : (
              <div className="navbar-collapse-button-close" />
            )}
          </button>

          <HeaderMenuMobile
            pages={pages}
            items={headerContent.node.acf.links}
            isCollapsed={isCollapsed}
            setCollapse={setCollapsed}
          />
          <HeaderMenuDesktop pages={pages} items={headerContent.node.acf.links} />
        </div>
        <div className="navbar lang-menu">
          {/* <LanguageSwitch /> */}
        </div>
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ isCollapsed, items, pages }) => (
  <CSSTransition
    in={!isCollapsed}
    timeout={0}
    unmountOnExit
    classNames="header-menu"
  >
    <div className="navbar-menu">
      <ul className="navbar-items">
        <HeaderMenuItems items={items} pages={pages} />
      </ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ items, pages }) => (
  <ul className="navbar-items d-none d-lg-flex">
    <HeaderMenuItems items={items} pages={pages} />
  </ul>
)

const HeaderMenuItems = ({ items, pages }) => (
  <>
    {items.map(pageId => {
      const page = _find(pages.edges, ({ node: { wordpressId } }) => wordpressId === pageId)

      return (
        <li key={page.node.wordpressId}>
          <span
            className="header-a"
          >
            <CustomLink to={page.node.path}>
              <span dangerouslySetInnerHTML={{ __html: page.node.title }} />
            </CustomLink>
          </span>
        </li>
      )
    })}
  </>
)

export default Header
