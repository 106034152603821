import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import parse from 'html-react-parser'

import {
  TitleSubDefault,
  ContentDefault,
  ButtonDefault,
} from 'components/Elements'

import CustomLink from 'components/CustomLink'

import { map as _map } from 'lodash'

// CSS
import './Footer.scss'

// Images
import arrow from 'img/arrow-right.svg'

const Footer = ({ subFooter }) => {
  const { footerItems } = useStaticQuery(graphql`
    {
      footerItems: wordpressWpComponents(wordpress_id: { eq: 25 }) {
        wordpress_id
        acf {
          subfooter_title
          subfooter_text
          subfooter_button_text
          subfooter_button_location
          footer_adress
          footer_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 262) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          footer_menu {
            post_title
            post_name
          }
          footer_downloads {
            footer_links {
              title
              url {
                localFile {
                  publicURL
                }
              }
            }
          }
          copyright_text_footer
        }
      }
    }
  `)

  return (
    <>
      {subFooter ? (
        <div className="subfooter color-background-contrast py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <TitleSubDefault className="mb-3">
                  {footerItems.acf.subfooter_title}
                </TitleSubDefault>
                <ContentDefault>
                  <div
                    className="color-text-light"
                    dangerouslySetInnerHTML={{
                      __html: footerItems.acf.subfooter_text,
                    }}
                  />
                </ContentDefault>
              </div>
              <div className="col-lg-1 d-none d-lg-block" />
              <div className="col-lg-4">
                <ButtonDefault to={footerItems.acf.subfooter_button_location}>
                  {footerItems.acf.subfooter_button_text}
                </ButtonDefault>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="color-background-contrast empty-space-50" />
      )}
      <div className="footer pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <Img
                className="mb-5"
                fluid={
                  footerItems.acf.footer_image.localFile.childImageSharp.fluid
                }
              />
              <ContentDefault>
                <div
                  dangerouslySetInnerHTML={{
                    __html: footerItems.acf.footer_adress,
                  }}
                />
              </ContentDefault>
            </div>
            <div className="col-lg-3">
              <h3 className="widgettitle text-uppercase mb-4 color-text-light">
                Menu
              </h3>
              <ul>
                {_map(footerItems.acf.footer_menu, (items, index) => (
                  <li key={index}>
                    <Link to={`/services/${items.post_name}`}>
                      {items.post_title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-3">
              <h3 className="widgettitle text-uppercase mb-4 color-text-light">
                Downloads
              </h3>
              <ul>
                {_map(footerItems.acf.footer_downloads, (download) => (
                  <li key={download.footer_links.title}>
                    <a href={download.footer_links.url.localFile.publicURL} target="_blank" rel="noopener noreferrer">
                      <img src={arrow} width="7" alt="" />
                      {download.footer_links.title}
                    </a>          
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 color-text-light font-size-s">
              {parse(footerItems.acf.copyright_text_footer)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
